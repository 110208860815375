<template>
    <div class="countdown">
        <div v-if="days" class="countdown__block">
            <div class="countdown__digit">{{ days  }}:</div>
            <!--<div class="countdown__text">Days</div>-->
        </div>
        <div class="countdown__block">
            <div class="countdown__digit">{{ hours  }}:</div>
            <!--<div class="countdown__text">Hours</div>-->
        </div>
        <div class="countdown__block">
            <div class="countdown__digit">{{ minutes  }}:</div>
            <!--<div class="countdown__text">Minutes</div>-->
        </div>
        <div class="countdown__block">
            <div class="countdown__digit">{{ seconds  }}</div>
            <!--<div class="countdown__text">Seconds</div>-->
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            begin: null,
            end: null
        },
        data() {
            return {
                now: Math.trunc(this.begin / 1000),
                event: this.end,
                finish: false
            }
        },
        mounted() {
            const _self = this
            window.setInterval(() => {
                this.now = Math.trunc((new Date()).getTime() / 1000)
                if (!this.finish && this.calculatedDate - this.now <= 0) {
                    _self.finish = true
                    _self.$emit('onFinish')
                }
            }, 1000)
        },
        computed: {
            secondCount() {
                return this.calculatedDate - this.now
            },
            calculatedDate() {
                return Math.trunc(Date.parse(this.event) / 1000)
            },
            seconds() {
                if (this.secondCount < 0) return 0
                return this.secondCount % 60
            },
            minutes() {
                if (this.secondCount < 0) return 0
                return Math.trunc(this.secondCount / 60) % 60
            },
            hours() {
                if (this.secondCount < 0) return 0
                return Math.trunc(this.secondCount / 60 / 60) % 24
            },
            days() {
                if (this.secondCount < 0) return 0
                return Math.trunc(this.secondCount / 60 / 60 / 24)
            },
            twoDigits(value) {
                if (value.toString().length <= 1) {
                    return '0' + value.toString()
                }
                return value.toString()
            }
        },
        filters: {
            /*
            twoDigits(value) {
                if (value.toString().length <= 1) {
                    return '0' + value.toString()
                }
                return value.toString()
            }
            * */
        }
    }
</script>
<style>
    .countdown {
        display: flex;
        color: white;
    }

    .countdown__block {
        display: block;
        padding: 1px;
    }

    .countdown__digit {
        color: white;
        font-weight: 600;
        text-align: center;
        position: relative;
        z-index: 4;
    }

    .countdown__text {
        text-align: center;
    }
</style>