<template>

    <nav class="navbar navbar-expand-lg navbar-light aos-item"
    >
        <div class="container container-fluid " >
            <a class="navbar-brand" href="#" >
                <img src="../../assets/logo.png" class="d-inline-block align-top" alt="Logo" style="width: 80px;">
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse " id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0" v-if="$route.name=='Landing'">
                    <li class="nav-item ">
                        <a href="javascript:void(0)" class="button active" data-ref="1" v-on:click='gtp(1)'>
                            Home
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="button" data-ref="2" v-on:click='gtp(2)'>
                            Introduction
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="button" data-ref="3" v-on:click='gtp(3)'>
                            About
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="button" data-ref="4" v-on:click='gtp(4)'>
                            Characteristic
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="button" data-ref="5" v-on:click='gtp(5)'>
                            Roadmap
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="button" data-ref="5" v-on:click='gtp(5)'>
                            Whitepaper
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="button" data-ref="5" v-on:click='gtp(5)'>
                            Moon
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="button" data-ref="6" v-on:click='gtp(6)'>
                            Team
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" v-on:click="goPresale()" class="button play">
                            Preventa
                        </a>
                    </li>
                </ul>

                <ul class="navbar-nav ms-auto mb-2 mb-lg-0" v-if="$route.name=='Presale'">
                    <li class="nav-item " style="margin-bottom: 10px; margin-top: 10px">
                        <a href="javascript:void(0)" v-on:click="goLanding()" class="button play">
                            Landing
                        </a>
                    </li>
                    <li class="nav-item" style="margin-bottom: 10px; margin-top: 10px"
                        v-if="!this.$store.state.connectMetamask">
                        <a href="javascript:void(0)" v-on:click="connectMetamask()"
                           rigth class="button play">Connect to metamask
                        </a>
                    </li>
                    <li class="nav-item" v-if="this.$store.state.connectMetamask">
                        <a href="javascript:void(0)" v-on:click="connectMetamask()"
                           rigth style="color: white;font-weight: bolder;margin-left: 10px;position:relative;top:22px;">
                            {{this.$store.state.addressAccount}}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>


</template>

<script>
    import LocalStorage from '../../LocalStorage.vue'
    import contracts from '../../contracts/Contracts.vue'
    import AOS from 'aos';
    import 'aos/dist/aos.css';

    export default {
        name: 'Header',
        data() {
            return {}
        },
        created() {
            let address = LocalStorage.getData('addressAccount');
            if (address != undefined) {
                this.$store.state.connectMetamask = true;
                this.$store.state.addressAccount = address;
            }

        },
        mounted() {
            AOS.init({
                mirror: true,
            });
        },
        methods: {
            goPresale() {
                this.$router.push({
                    name: 'Presale',
                    params: {}
                });
            },
            goLanding() {
                this.$router.push({
                    name: 'Landing',
                    params: {}
                });
            },
            connectMetamask() {
                contracts.connectMetamask(this.$store)
            },

            gtp(x) {
                this.$store.state.page.scrollToPage(x);
                $('nav .button').removeClass('active');
                $('*[data-ref="' + x + '"]').addClass('active');
            }

        }
    }
</script>

<style>
    .navbar {
        position: fixed !important;
        top: 0;
        width: 100%;
        z-index: 3;
    }
</style>
