import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import BootstrapVue3 from 'bootstrap-vue-3'
import VueParticles from 'vue-particles'
import { TroisJSVuePlugin } from 'troisjs';


import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

createApp(App)
    .use(store)
    .use(router)
    .use(BootstrapVue3)
    .use(VueParticles)
    .use(TroisJSVuePlugin)
    .mount('#app')
