import {createStore} from 'vuex'

export default createStore({
    state: {
        page: null, // Controlador para la paginas del landing
        viewLanding: true, // Indica si se está viendo o no el landing
        connectMetamask: false,
        addressAccount: null
    },
    mutations: {},
    actions: {},
    modules: {}
})
