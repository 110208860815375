<script>
    export default {
        setData(item, arr) {
            localStorage.setItem(item, JSON.stringify(arr));
        },
        getData(item) {
            return JSON.parse(localStorage.getItem(item));
        },
    }

</script>