<template>
    <div class="home home-presale">
        <vue-particles style="position: absolute;top:0;width: 100%;height: 100%;z-index: 0;"
                       color="#dedede"></vue-particles>

        <div id="container" orientation="vertical" style="height: 100%;z-index: 1;">
            <div data-anchor="home" data-index="0">
                <div class="boxed" style="justify-content: center;max-width:500px;">
                    <div class="row" style="justify-content: center">
                        <div class="bgMona"
                             style="bottom: unset;width: 810px;max-width: unset;left: 50%;transform: translateX(-50%);top: 110px;"></div>
                        <div class="presentation animate__animated animate__fadeInDown">
                            <img src="../../assets/landing/img/dapp.png">
                            <div class="title">
                                Get your Own KITSUU NFT
                            </div>

                            <div class="desc">
                                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua.”
                            </div>

                            <div class="indicators">
                                <div class="indicator">
                                    <div class="title">
                                        <CountDown :begin="begin" :end="end"/>
                                    </div>
                                    <div class="desc">FCS Start</div>
                                </div>

                                <div class="indicator" style="left:5px;">
                                    <div class="title">{{ sells }}</div>
                                    <div class="desc">Sells</div>
                                </div>

                                <div class="indicator" style="left:18px;">
                                    <div class="title">{{available}}</div>
                                    <div class="desc">Available</div>
                                </div>

                                <div class="indicator" style="left:21px;">
                                    <div class="title">{{owners}}</div>
                                    <div class="desc">Owners</div>
                                </div>
                            </div>

                            <div class="connect">
                                <button
                                    v-on:click="connectMetamask()"
                                    v-if="!this.$store.state.connectMetamask">Connect Wallet
                                </button>
                                <button v-if="this.$store.state.connectMetamask">Mint NFT</button>
                            </div>
                        </div>


                    </div>
                </div>

                <TabGroup >
                    <Tab :active="tab.active" :index="i" @activeTab="showActive" v-for="tab, i in tabs">
                        {{tab.label}}
                    </Tab>
                </TabGroup>

                <div style="z-index: 8; position: relative">
                    <keep-alive>
                        <component :is="showTab"></component>
                    </keep-alive>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import CountDown from '@/components/CountDown.vue'
    import moment from 'moment'
    import contracts from '../../contracts/Contracts.vue'
    import TabGroup from '@/components/TabGroup.vue'
    import Tab from '@/components/Tab.vue'

    import Mint from './Mint.vue'
    import Collection from './Collection.vue'

    export default {
        name: 'Home',
        data() {
            return {
                begin: moment(), //Fecha de referencia
                end: moment("2022/12/29 24:00:00", "YYYY/MM/DD h:mm:ss"), //Fecha de fin
                sells: 20,
                available: 3000,
                owners: 10,
                tabs:[
                    {
                        "label": "What can I Win?",
                        "active": true,
                        "component": 'Mint'
                    },
                    {
                        "label": "My Collection",
                        "active": false,
                        "component": 'Collection'
                    },
                ],
                showTab: 'Mint'
            }
        },
        mounted() {
            // let page = new Pageable("#container", {})
        },
        computed: {},
        components: {
            CountDown,
            TabGroup,
            Tab,
            Mint,
            Collection
        },
        methods: {
            connectMetamask() {
                contracts.connectMetamask(this.$store)
            },
            showActive(index) {
                for(let i in this.tabs){
                    this.tabs[i].active = false;
                }
                this.tabs[index].active = true;
                this.showTab = this.tabs[index].component;
            }
        }
    }
</script>

<style scoped>
    .home {
        height: 100%;
    }

    .vuejs-countdown .digit {
        font-weight: 600;
        line-height: 1.4;
        margin-bottom: 0;
        font-size: 84px !important;
    }
</style>
