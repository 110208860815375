<template>
    <div class="tabgroup">
        <slot/>
    </div>
</template>

<script>
    import Tab from './Tab'

    export default {
        name: "TabGroup",
        data() {
            return {
                "item": 0
            }
        },
        components: {
            Tab
        },
        methods: {

        }
    }
</script>

<style scoped>

</style>