<template>

    <div class="home">
        <div
            style="position: absolute; bottom: 0; left: 0; width: 100%; background: white; padding: 10px; z-index: 9;display:none">
            <p>Camera</p>
            <div class="row" style="">
                <div class="col-md-4">
                    <label for="">X</label>
                    <input type="number" step="0.1" v-model="sliders[0].camera.x" style="z-index: 10;">
                </div>
                <div class="col-md-4">
                    <label for="">Y</label>
                    <input type="number" step="0.1" v-model="sliders[0].camera.y" style="z-index: 10;">
                </div>
                <div class="col-md-4">
                    <label for="">Z</label>
                    <input type="number" step="0.1" v-model="sliders[0].camera.z" style="z-index: 10;">
                </div>
            </div>
            <p>Escena</p>
            <div class="row">
                <div class="col-md-4">
                    <label for="">X</label>
                    <input type="number" step="0.1" v-model="sliders[0].position.x" style="z-index: 10;">
                </div>
                <div class="col-md-4">
                    <label for="">Y</label>
                    <input type="number" step="0.1" v-model="sliders[0].position.y" style="z-index: 10;">
                </div>
                <div class="col-md-4">
                    <label for="">Z</label>
                    <input type="number" step="0.1" v-model="sliders[0].position.z" style="z-index: 10;">
                </div>
            </div>
        </div>


        <vue-particles style="position: fixed;top:0;width: 100%;height: 100%;z-index: 1"
                       color="#dedede"></vue-particles>


        <div id="container" orientation="vertical">
            <div data-anchor="home" data-index="0">
                <div class="boxed">
                    <div class="row rowHome">
                        <!-- <div class="spacer"></div> -->
                        <div class="col-sm-12 col-md-7 prz2 aos-item"
                             data-aos="fade-right" data-aos-anchor-placement="top-center">
                            <div class="descripcion-inicial">
                                Lorem ipsum dolor
                                sit amet, <span class="texto-resaltado">consectetur</span>
                                adipiscing elit
                            </div>
                            <div class="descripcion-general" data-animate data-animate-in="animate__fadeInDown"
                                 data-animate-out="animate__fadeOutUp">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore
                                et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                laboris nisi ut
                                aliquip ex ea commodo consequat.
                            </div>
                            <div class="buttons-init" data-animate data-animate-in="animate__fadeInLeft"
                                 data-animate-out="animate__fadeOutDown">
                                <button>Jugar</button>
                                <button style="margin-left: 15px">Market Place</button>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-5 card-monas"
                             data-aos="fade-left" data-aos-anchor-placement="top-center"
                             style="margin-top: 25px" data-animate
                             data-animate-in="animate__flash"
                             data-animate-out="animate__fadeOutUp">
                            <img src="../../assets/landing/img/circuit.png">
                            <img src="../../assets/landing/img/cards.png">
                        </div>
                    </div>
                </div>
            </div>

            <div data-anchor="page2" data-index="1">
                <div class="bgMona" data-aos="zoom-in" data-aos-anchor-placement="top-center"></div>
                <div class="boxed">
                    <div class="content-frontal">
                        <h1 class="title"
                        data-aos="zoom-out" data-aos-anchor-placement="top-center"
                        >Introduction</h1>
                        <p data-animate data-aos="zoom-out" data-aos-anchor-placement="top-center">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                            voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>

                        <div data-aos="fade-up" data-aos-anchor-placement="top-center">
                            <div class="icons-grid">
                                <div class="icon icon1"></div>
                                <div class="icon icon2"></div>
                                <div class="icon icon3"></div>
                            </div>
                            <div class="icons-grid" >
                                <div class="icon icon4"></div>
                                <div class="icon icon5"></div>
                            </div>
                        </div>

                    </div>

                    <img src="../../assets/landing/img/c1.png"
                         class="coin1 animate__animated animate__pulse animate__infinite">
                    <img src="../../assets/landing/img/c1.png"
                         class="coin2 animate__animated animate__pulse animate__infinite">
                    <img src="../../assets/landing/img/c2.png"
                         class="coin3 animate__animated animate__pulse animate__infinite">
                </div>
            </div>

            <div data-anchor="page3" data-index="2">
                <br><br><br>
                <h1 class="title" data-aos="zoom-in" data-aos-anchor-placement="top-center">What is the game about?</h1>
                <div class="boxed chario">
                    <div class="container" style="margin-top: 20px">
                        <div class="row">
                            <div class="col-md-4" data-aos="fade-right" data-aos-anchor-placement="top-center">
                                <img class="women-box01" src="../../assets/landing/img/women-box01.png" alt="">
                            </div>
                            <div class="col-md-8" data-aos="fade-left" data-aos-anchor-placement="top-center">
                                <h1>Lorem ipsum</h1>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut
                                    labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                    ullamco
                                    laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                                    reprehenderit
                                    in
                                    voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                </p>
                            </div>
                        </div>
                        <div class="row" data-animate data-animate-in="animate__fadeInRight"
                             data-animate-out="animate__fadeOutLeft">
                            <div class="col-md-8" data-aos="fade-right" data-aos-anchor-placement="bootom-center">
                                <h1>Lorem ipsum</h1>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut
                                    labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                    ullamco
                                    laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                                    reprehenderit
                                    in
                                    voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                </p>
                            </div>
                            <div class="col-md-4" data-aos="fade-left" data-aos-anchor-placement="bootom-center">
                                <img class="women-box02" src="../../assets/landing/img/women-box02.png" alt="">
                            </div>
                        </div>
                    </div>


                    <img src="../../assets/landing/img/c2.png"
                         class="coin4 animate__animated animate__pulse animate__infinite">
                    <img src="../../assets/landing/img/c1.png"
                         class="coin5 animate__animated animate__pulse animate__infinite">
                    <img src="../../assets/landing/img/c2.png"
                         class="coin6 animate__animated animate__pulse animate__infinite">
                    <img src="../../assets/landing/img/c1.png"
                         class="coin7 animate__animated animate__pulse animate__infinite">
                </div>

            </div>

            <div data-anchor="page4" data-index="3">
                <br><br><br>
                <h1 class="title" data-aos="zoom-in" data-aos-anchor-placement="top-center">Characteristic</h1>

                <div class="boxed chario">
                    <div class="container">
                        <div class="row" style="justify-content: center;">

                            <div style="height: 500px">


                                <section>
                                    <div class="container">
                                        <div class="carousel">
                                            <input type="radio" name="slides"
                                                   v-bind:id="'slide-'+(i+1)"
                                                   v-for="slider, i in sliders">
                                            <ul class="carousel__slides">
                                                <li class="carousel__slide" v-for="slider, i in sliders">
                                                    <figure style="height: 100%;">
                                                        <div style="display: initial"
                                                        data-aos="fade-right" data-aos-anchor-placement="top-center"
                                                        >
                                                            <MModel
                                                                :id="i"
                                                                :src="slider.src"
                                                                :texture="sliders.texture"
                                                                :camXpos="slider.camera.x"
                                                                :camYpos="slider.camera.y"
                                                                :camZpos="slider.camera.z"
                                                                :scXpos="slider.position.x"
                                                                :scYpos="slider.position.y"
                                                                :scZpos="slider.position.z"
                                                                :rotateY="slider.rotate.y"
                                                            />
                                                        </div>
                                                        <figcaption  data-aos="fade-left" data-aos-anchor-placement="top-center">
                                                            {{slider.name}}
                                                            <span class="credit">Photo: Tim Marshall</span>
                                                        </figcaption>
                                                    </figure>
                                                </li>
                                            </ul>
                                            <ul class="carousel__thumbnails">
                                                <li v-for="slider, i in sliders">
                                                    <label v-bind:for="'slide-'+(i+1)"><img
                                                        src="/landing/img/avatars.png" alt=""></label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </section>


                                <!--
                                <div class="container" style="width: 100%">
                                    <input type="radio" name="slider" id="item-1" checked>
                                    <input type="radio" name="slider" id="item-2">
                                    <div class="cards">
                                        <label class="card"
                                               v-bind:for="'item-'+(i+1)"
                                               v-bind:id="'song-'+(i+1)"
                                               v-for="slider, i in sliders">
                                            <MModel
                                                :id="i"
                                                :src="slider.src"
                                                :texture="sliders.texture"
                                                :camXpos="slider.camera.x"
                                                :camYpos="slider.camera.y"
                                                :camZpos="slider.camera.z"
                                                :scXpos="slider.position.x"
                                                :scYpos="slider.position.y"
                                                :scZpos="slider.position.z"
                                                :rotateY="slider.rotate.y"
                                            />
                                        </label>
                                    </div>
                                    <div class="player">
                                        <div class="upper-part">
                                            <div class="play-icon">
                                                <svg width="20" height="20" fill="#2992dc" stroke="#2992dc"
                                                     stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                     class="feather feather-play" viewBox="0 0 24 24">
                                                    <defs/>
                                                    <path d="M5 3l14 9-14 9V3z"/>
                                                </svg>
                                            </div>
                                            <div class="info-area" id="test">
                                                <label class="song-info"
                                                       v-bind:id="'song-info-'+(i+1)"
                                                       v-for="slider, i in sliders">
                                                    <div class="title">{{slider.name}}</div>
                                                    <div class="sub-line">
                                                        <div class="subtitle">Balthazar</div>
                                                        <div class="time">4.05</div>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="progress-bar">
                                            <span class="progress"></span>
                                        </div>
                                    </div>
                                </div>
                                -->

                                <!--
                                <div class="carrusel">
                                    <main id="carousel">
                                        <div class="item"></div>
                                        <div class="item"></div>
                                        <div class="item"></div>
                                        <div class="item"></div>
                                        <div class="item"></div>
                                    </main>
                                    <input type="radio" name="position" checked/>
                                    <input type="radio" name="position"/>
                                    <input type="radio" name="position"/>
                                    <input type="radio" name="position"/>
                                    <input type="radio" name="position"/>
                                </div>
                                -->


                                <!--
                                <carousel :items-to-show="1.5">
                                    <slide :key="i" v-for="slider, i in sliders">
                                        <div style="height: 500px; width: 100%;">
                                            <MModel
                                                :id="i"
                                                :src="slider.src"
                                                :texture="sliders.texture"
                                                :camXpos="slider.camera.x"
                                                :camYpos="slider.camera.y"
                                                :camZpos="slider.camera.z"
                                                :scXpos="slider.position.x"
                                                :scYpos="slider.position.y"
                                                :scZpos="slider.position.z"
                                                :rotateY="slider.rotate.y"
                                            />
                                            <p>{{slider.camera.z}}</p>
                                        </div>
                                    </slide>
                                    <template #addons>
                                        <navigation/>
                                        <pagination/>
                                    </template>

                                </carousel>
                                -->


                                <!--:texture="'/models/seraphine/textures/Mat_cwfyfr1_userboy17.bmp_diffuse.png'"-->

                            </div>

                            <div
                                style="position: absolute;z-index: -1;opacity: 0.4;display:none;">
                                <img src="../../assets/landing/img/circuit2.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div data-anchor="page5" data-index="4">
                <br><br><br>
                <h1 class="title" data-aos="zoom-in" data-aos-anchor-placement="top-center">Roadmap</h1>

                <div class="boxed" style="max-width: initial;z-index: 2;"
                data-aos="fade-in" data-aos-anchor-placement="top-center">

                    <div id="sc" style="width: 100%;display: flex;overflow: hidden; position: relative;"
                         data-animate data-animate-in="animate__fadeIn" data-animate-out="animate__fadeOut">

                        <div style="display: flex;height: 420px; margin-top: 120px">

                            <div class="lineal line-horizontal-bottom"></div>
                            <div class="lineal line-vertical">
                                <div class="point point1 point-color1">
                                    <p class="left">Lorem Ipsum</p>
                                </div>
                            </div>
                            <div class="lineal line-horizontal">
                                <h1>Q1 2022</h1>
                            </div>
                            <div class="lineal line-vertical" style="height: 400px;">
                                <div class="point point2 point-color1">
                                    <p class="right">Lorem Ipsum</p>
                                </div>
                            </div>
                            <div class="lineal line-horizontal-bottom" style="height: 390px"></div>

                            <div class="lineal line-vertical" style="height: 400px">
                                <div class="point point1 point-color1">
                                    <p class="left">Lorem Ipsum</p>
                                </div>
                                <div class="point point2 point-color1">
                                    <p class="left">Lorem Ipsum</p>
                                </div>
                            </div>
                            <div class="lineal line-horizontal"></div>
                            <div class="lineal line-vertical">
                                <div class="point point3 point-color1">
                                    <p class="left">Lorem Ipsum</p>
                                </div>
                            </div>
                            <div class="lineal line-horizontal-bottom">
                                <div class="point-h point3 point-color1">
                                    <p class="top">Lorem Ipsum</p>
                                </div>
                            </div>

                            <div class="lineal line-vertical">
                                <div class="point point1 point-color2">
                                    <p class="left">Lorem Ipsum</p>
                                </div>
                            </div>
                            <div class="lineal line-horizontal">
                                <h1>Q2 2022</h1>
                            </div>
                            <div class="lineal line-vertical"></div>
                            <div class="lineal line-horizontal-bottom"></div>

                            <div class="lineal line-vertical"></div>
                            <div class="lineal line-horizontal"></div>
                            <div class="lineal line-vertical"></div>
                            <div class="lineal line-horizontal-bottom"></div>
                        </div>

                    </div>


                </div>
            </div>

            <div data-anchor="page5" data-index="4">
                <br><br><br>
                <h1 class="title" data-aos="zoom-in" data-aos-anchor-placement="top-center">Whitepaper</h1>

                <div class="boxed" style="max-width: initial;z-index: 2;">


                </div>
            </div>

            <div data-anchor="page5" data-index="4">
                <br><br><br>
                <h1 class="title" data-aos="zoom-in" data-aos-anchor-placement="top-center">Moon</h1>

                <img class="moon-img" width="100" height="200" src="/landing/img/moon.png" alt="">

                <div class="boxed" style="max-width: initial;z-index: 2;">


                </div>
            </div>

            <div data-anchor="page6" data-index="5">
                <div class="bgMona"></div>
                <div>
                    <h1 class="title" style="margin: 30px 0;"
                    data-aos="zoom-in" data-aos-anchor-placement="top-center"
                    >Team</h1>
                    <div class="boxed team"
                         data-aos="fade-in" data-aos-anchor-placement="top-center"
                         style="justify-content: center; max-width: 1000px; padding-bottom: 40px;overflow: hidden;">

                        <Carousel :items-to-show="1" :nav="true">
                            <slide :key="slide">
                                <div class="p">
                                    <div class="col-md-12 col-sm-12"
                                         data-animate-out="animate__fadeOutRight">
                                        <div class="avatar">
                                            <img class="rounded-corners-gradient-borders"
                                                 src="../../assets/landing/img/avatars.png" alt="">
                                            <p>Joel</p>
                                            <i>CEO</i>
                                        </div>
                                    </div>
                                </div>
                                <div class="p">
                                    <div class="col-md-12 col-sm-12"
                                         data-animate-out="animate__fadeOutRight">
                                        <div class="avatar">
                                            <img class="rounded-corners-gradient-borders"
                                                 src="../../assets/landing/img/avatars.png" alt="">
                                            <p>Frank</p>
                                            <i>Economist</i>
                                        </div>
                                    </div>
                                </div>
                                <div class="p">
                                    <div class="col-md-12 col-sm-12"
                                         data-animate-out="animate__fadeOutRight">
                                        <div class="avatar">
                                            <img class="rounded-corners-gradient-borders"
                                                 src="../../assets/landing/img/avatars.png" alt="">
                                            <p>xCh41n</p>
                                            <i>CTO </i>
                                        </div>
                                    </div>
                                </div>
                                <div class="p">
                                    <div class="col-md-12 col-sm-12"
                                         data-animate-out="animate__fadeOutRight">
                                        <div class="avatar">
                                            <img class="rounded-corners-gradient-borders"
                                                 src="../../assets/landing/img/avatars.png" alt="">
                                            <p>Posty</p>
                                            <i>Dir. of Dev. and VR</i>
                                        </div>
                                    </div>
                                </div>
                            </slide>

                            <slide :key="slide">
                                <div class="p">
                                    <div class="col-md-12 col-sm-12"
                                         data-animate-out="animate__fadeOutRight">
                                        <div class="avatar">
                                            <img class="rounded-corners-gradient-borders"
                                                 src="../../assets/landing/img/avatars.png" alt="">
                                            <p>TrapMaster</p>
                                            <i>Design 2D Artist</i>
                                        </div>
                                    </div>
                                </div>
                                <div class="p">
                                    <div class="col-md-12 col-sm-12"
                                         data-animate-out="animate__fadeOutRight">
                                        <div class="avatar">
                                            <img class="rounded-corners-gradient-borders"
                                                 src="../../assets/landing/img/avatars.png" alt="">
                                            <p>Sexy Chocolat</p>
                                            <i>Design 3D Artist</i>
                                        </div>
                                    </div>
                                </div>
                                <div class="p">
                                    <div class="col-md-12 col-sm-12"
                                         data-animate-out="animate__fadeOutRight">
                                        <div class="avatar">
                                            <img class="rounded-corners-gradient-borders"
                                                 src="../../assets/landing/img/avatars.png" alt="">
                                            <p>NaujSepiol</p>
                                            <i>Design 3D Artist</i>
                                        </div>
                                    </div>
                                </div>
                                <div class="p">
                                    <div class="col-md-12 col-sm-12"
                                         data-animate-out="animate__fadeOutRight">
                                        <div class="avatar">
                                            <img class="rounded-corners-gradient-borders"
                                                 src="../../assets/landing/img/avatars.png" alt="">
                                            <p>ChocoMilk</p>
                                            <i>VR Dev</i>
                                        </div>
                                    </div>
                                </div>
                            </slide>

                            <slide :key="slide">
                                <div class="p">
                                    <div class="col-md-12 col-sm-12"
                                         data-animate-out="animate__fadeOutRight">
                                        <div class="avatar">
                                            <img class="rounded-corners-gradient-borders"
                                                 src="../../assets/landing/img/avatars.png" alt="">
                                            <p>Mr. Chang</p>
                                            <i>Full Stack</i>
                                        </div>
                                    </div>
                                </div>
                                <div class="p">
                                    <div class="col-md-12 col-sm-12"
                                         data-animate-out="animate__fadeOutRight">
                                        <div class="avatar">
                                            <img class="rounded-corners-gradient-borders"
                                                 src="../../assets/landing/img/avatars.png" alt="">
                                            <p>Nox</p>
                                            <i>Full Stack</i>
                                        </div>
                                    </div>
                                </div>
                                <div class="p">
                                    <div class="col-md-12 col-sm-12"
                                         data-animate-out="animate__fadeOutRight">
                                        <div class="avatar">
                                            <img class="rounded-corners-gradient-borders"
                                                 src="../../assets/landing/img/avatars.png" alt="">
                                            <p>Venat</p>
                                            <i>Backend</i>
                                        </div>
                                    </div>
                                </div>
                                <div class="p">
                                    <div class="col-md-12 col-sm-12"
                                         data-animate-out="animate__fadeOutRight">
                                        <div class="avatar">
                                            <img class="rounded-corners-gradient-borders"
                                                 src="../../assets/landing/img/avatars.png" alt="">
                                            <p>-</p>
                                            <i>Marketing </i>
                                        </div>
                                    </div>
                                </div>
                            </slide>

                            <slide :key="slide">
                                <div class="p">
                                    <div class="col-md-12 col-sm-12"
                                         data-animate-out="animate__fadeOutRight">
                                        <div class="avatar">
                                            <img class="rounded-corners-gradient-borders"
                                                 src="../../assets/landing/img/avatars.png" alt="">
                                            <p>Jaime Sama</p>
                                            <i>Project Manager</i>
                                        </div>
                                    </div>
                                </div>
                                <div class="p">
                                    <div class="col-md-12 col-sm-12"
                                         data-animate-out="animate__fadeOutRight">
                                        <div class="avatar">
                                            <img class="rounded-corners-gradient-borders"
                                                 src="../../assets/landing/img/avatars.png" alt="">
                                            <p>-</p>
                                            <i>Community Manager</i>
                                        </div>
                                    </div>
                                </div>
                                <div class="p">
                                    <div class="col-md-12 col-sm-12"
                                         data-animate-out="animate__fadeOutRight">
                                        <div class="avatar">
                                            <img class="rounded-corners-gradient-borders"
                                                 src="../../assets/landing/img/avatars.png" alt="">
                                            <p>-</p>
                                            <i>Mods</i>
                                        </div>
                                    </div>
                                </div>
                            </slide>

                            <template #addons>
                                <pagination/>
                                <navigation/>
                            </template>
                        </Carousel>


                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    /*
    import {
        AmbientLight,
        Camera,
        DirectionalLight,
        FbxModel,
        HemisphereLight,
        Renderer,
        PhongMaterial,
        Plane,
        Scene,
        GltfModel,
        ShaderMaterial,
        ToonMaterial
    } from 'troisjs';*/

    import MModel from '../../components/3DModel'

    //import 'vue3-carousel/dist/carousel.css';
    import {Carousel, Slide, Pagination, Navigation} from 'vue3-carousel';

    export default {
        name: 'Landing',
        data() {
            return {
                sliders: [
                    {
                        src: "/models/seraphine/scene.gltf",
                        texture: "/models/seraphine/texture/Mat_cwfyfr1_userboy17.bmp_diffuse.png",
                        name: "Rarity1",
                        camera: {
                            x: 0,
                            y: 0,
                            z: 17.6
                        },
                        position: {
                            x: 0,
                            y: -0.8,
                            z: 5.8
                        },
                        rotate: {
                            x: 0,
                            y: 0,
                            z: 0
                        }
                    },
                    /*
                    {
                        src: "/models/scifi_girl_v.01/scene.gltf",
                        texture: undefined,
                        name: "Rarity2",
                        camera: {
                            x: 0,
                            y: 0,
                            z: 19.7
                        },
                        position: {
                            x: 0,
                            y: -1,
                            z: 5.8
                        },
                        rotate: {
                            x: 0,
                            y: 0,
                            z: 0
                        }
                    },
                    */
                    {
                        src: "/models/sorceress/scene.gltf",
                        texture: undefined,
                        name: "Rarity3",
                        camera: {
                            x: 0,
                            y: -3.7,
                            z: 29
                        },
                        position: {
                            x: 0,
                            y: -0.8,
                            z: 5.8
                        },
                        rotate: {
                            x: 0,
                            y: 0,
                            z: 0
                        }
                    },
                ],
                option: {
                    antialias: true,
                },
                sliderShow: 0,
                camXpos: 0,
                camYpos: 0,
                camZpos: 17.6,
                scXpos: 0,
                scYpos: -0.8,
                scZpos: 5.8,
            }
        },
        created() {

        },
        mounted() {
            /*
            let page = new Pageable("#container", {
                animation: 800,
                swipeThreshold: 80,
                easing: function (currentTime, startPos, endPos, interval) {
                    return -endPos * (currentTime /= interval) * (currentTime - 2) + startPos;
                },
                onStart: function () {
                    $('[data-animate]').each(function () {
                        let effect_in = $(this).data('animate-in');
                        let effect_out = $(this).data('animate-out');
                        $(this).removeClass(effect_in);
                        $(this).addClass("animate__animated " + effect_out);
                    })
                },
                onInit: function (object) {
                    $('[data-animate]').each(function () {
                        let effect_in = $(this).data('animate-in');
                        let effect_out = $(this).data('animate-out');
                        $(this).removeClass(effect_in);
                        $(this).addClass("animate__animated " + effect_out);
                    })

                    let data_animate = $("[data-index=" + object.index + "]").find('[data-animate]');
                    data_animate.each(function () {
                        let effect_in = $(this).data('animate-in');
                        let effect_out = $(this).data('animate-out');
                        $(this).removeClass(effect_out);
                        $(this).addClass("animate__animated " + effect_in);
                    })
                }
            });
            page.on("scroll", (object) => {
                let data_animate = $("[data-index=" + object.index + "]").find('[data-animate]');
                data_animate.each(function () {
                    let effect_in = $(this).data('animate-in');
                    let effect_out = $(this).data('animate-out');
                    $(this).removeClass(effect_out);
                    $(this).addClass("animate__animated " + effect_in);
                })
            });
            this.$store.state.page = page;
            */
            const container = document.querySelector('#sc');
            const ps = new PerfectScrollbar(container);

        },
        computed: {},
        components: {
            MModel,
            Carousel,
            Slide,
            Pagination,
            Navigation,
        },
        methods: {}
    }
</script>

<style scoped>

</style>
