import {createRouter, createWebHistory} from 'vue-router'
import Landing from '../views/Landing/Home.vue'
import Presale from '../views/Presale/Home.vue'

const routes = [
    {
        path: '/',
        name: 'Landing',
        component: Landing
    },
    {
        path: '/presale/',
        name: 'Presale',
        component: Presale
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
