<template>
    <div class="tab " v-bind:class="{'active':active}" v-on:click="activeTab">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "Tab",
        props:{
            active: Boolean,
            index: Number
        },
        mounted() {
            this.$emit("setItem", 5);
            console.log(this.active)
        },
        methods:{
            activeTab(){
                this.$emit("activeTab", this.index)
            }
        }
    }
</script>

<style scoped>

</style>