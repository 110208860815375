<template>
    <div style="height: 100%; ">
        <canvas style="width: 360px;" v-bind:id="'three'+id"></canvas>
    </div>
</template>

<script>
    import * as THREE from 'three'
    import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js'
    import {
        OrbitControls
    } from 'three/examples/jsm/controls/OrbitControls'

    export default {
        props: {
            id: String,
            src: String,
            texture: String,
            camXpos: String,
            camYpos: String,
            camZpos: String,
            scXpos: String,
            scYpos: String,
            scZpos: String,
            rotateY: String
        },
        mounted() {
            this.initThree()
            console.log(this.$el)
            this.windowsHeight = this.$el.clientHeight;
            console.log(this.windowsHeight)
        },
        data() {
            return {
                windowsHeight: 0
            }
        },
        methods: {
            initThree() {
                const scene = new THREE.Scene()
                const canvas = document.querySelector('#three'+this.id)
                const renderer = new THREE.WebGLRenderer({canvas, antialias: true, alpha: true})
                // Generar sombras
                renderer.shadowMap.enabled = true;

                // Fondo
                //scene.background = new THREE.Color('#eee')
                // Niebla en el fondo
                //scene.fog = new THREE.Fog('#eee', 20, 100)
                const camera = new THREE.PerspectiveCamera(
                    10,
                    this.$el.clientWidth / this.windowsHeight,
                    0.5,
                    1000
                )
                camera.position.z = this.camZpos
                camera.position.y = this.camYpos
                camera.position.x = this.camXpos
                //scene.position.set(this.scXpos, this.scYpos, this.scZpos);

                scene.rotateY(this.rotateY)



                const gltfLoader = new GLTFLoader()
                gltfLoader.load(this.src, (gltf) => {
                    let model = gltf.scene

                    model.traverse((o) => {
                        if (this.texture != undefined) {
                            let mtexture = new THREE.TextureLoader().load(
                                this.texture
                            )
                            mtexture.flipY = false
                            const material = new THREE.MeshBasicMaterial({
                                map: mtexture,
                            })

                            o.material = material;
                        }

                        // Complemento para generar las combras
                        if (o.isMesh) {
                            //o.castShadow = true
                            o.receiveShadow = true
                        }
                    })


                    scene.add(model)
                })

                let $this = this;

                function resizeRendererToDisplaySize(renderer) {
                    const canvas = renderer.domElement
                    var width = $this.$el.clientWidth
                    var height = $this.windowsHeight
                    var canvasPixelWidth = canvas.width / window.devicePixelRatio
                    var canvasPixelHeight = canvas.height / window.devicePixelRatio

                    const needResize = canvasPixelWidth !== width || canvasPixelHeight !== height
                    if (needResize) {
                        renderer.setSize(width, height, false)
                    }
                    return needResize
                }


                const controls = new OrbitControls(camera, renderer.domElement);
                controls.enableDamping = true;
                controls.enabled = false;
                controls.update()


                async function animate() {
                    controls.update()
                    renderer.render(scene, camera)
                    requestAnimationFrame(animate)

                    if (resizeRendererToDisplaySize(renderer)) {
                        const canvas = renderer.domElement
                        camera.aspect = canvas.clientWidth / canvas.clientHeight
                        camera.updateProjectionMatrix()
                    }

                    camera.position.set($this.camXpos, $this.camYpos, $this.camZpos)
                    scene.position.set($this.scXpos, $this.scYpos, $this.scZpos);
                    scene.rotateY(0.01)//$this.rotateY
                }

                animate()

                /*
                // Plano en el piso
                let floorGeometry = new THREE.PlaneGeometry(3000, 3000)
                let floorMaterial = new THREE.MeshPhongMaterial({color: 0xffffff})

                let floor = new THREE.Mesh(floorGeometry, floorMaterial)
                floor.rotation.x = -0.5 * Math.PI
                floor.receiveShadow = true
                floor.position.y = -0.001
                scene.add(floor)
                */

                // Luz paralela, generalmente se usa para simular la luz solar
                const dirLight = new THREE.DirectionalLight(0xffffff, 1.6)
                dirLight.position.set(10, 60, 5)
                dirLight.castShadow = true
                dirLight.shadow.mapSize = new THREE.Vector2(1024, 1024)
                scene.add(dirLight)

                // Luz del hemisferio se coloca directamente en la escena y el color de la luz se desvanece del color de la luz del cielo al color de la luz del suelo.
                // 1er valor (skyColor El color de la luz emitida en el cielo. El valor predeterminado es 0xffffff.)
                // 2do valor (groundColor - (opcional) El color de la luz emitida por el suelo. El valor predeterminado es 0xffffff. )
                // 3er valor (intensidad - (parámetro opcional) Intensidad de la luz. El valor predeterminado es 1.)
                const hemLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 0.8)
                hemLight.position.set(0, 48, 10)
                scene.add(hemLight)


            },


        },
    }
</script>

<style scoped>
    #three {

    }
</style>
