<template>
    <div class="boxed animate__animated animate__fadeInUp" style="justify-content: center;  margin-bottom: 200px;margin-top: 30px;">
        <div class="container-col">
            <div class="row">
                <p style="text-align: center; color: #9D93CC; font-size: 16px;margin-top: 10px;">
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et
                    dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip
                    ex
                    ea commodo consequat.
                </p>
            </div>

            <div class="row">
                <div class="col-md-3" v-for="collection in collections">
                    <div class="card" style="background: none;">
                        <div class="card-body">
                            <img class="img-fluid" src="../../assets/landing/img/waifu1.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
    export default {
        name: "Collection",
        data(){
            return{
                collections: [
                    {
                        name: "Waifu Human 1"
                    },
                    {
                        name: "Waifu Human 2"
                    },
                    {
                        name: "Waifu Human 3"
                    },
                    {
                        name: "Waifu Human 4"
                    },
                    {
                        name: "Waifu Human 5"
                    },
                    {
                        name: "Waifu Human 6"
                    },
                    {
                        name: "Waifu Human 7"
                    },
                    {
                        name: "Waifu Human 8"
                    },
                ]
            }
        }
    }
</script>

<style scoped>

</style>