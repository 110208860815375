<script>
    import LocalStorage from '../LocalStorage.vue'

    export default {
        connectMetamask($store) {
            this.auth((install, account) => {
                if (install) {
                    if(account[0]!=undefined){
                        $store.state.connectMetamask = true;
                        $store.state.addressAccount = account[0];
                        LocalStorage.setData('addressAccount', account[0])
                    }

                }
                else {
                    console.log('Se detectó un navegador que no es Ethereum. ¡Deberías considerar probar MetaMask!');
                }
            })
        },
        async auth(callback) {
            if (window.ethereum) { //Si hay algun proveedor
                window.web3 = new Web3(ethereum); //Iniciar web3
                try {
                    let accounts = await
                        ethereum.enable(); //Sacar ventana de coneccion metamask
                    callback(true, accounts) //Si se vincula
                } catch (error) {
                    callback(true, false) // Si rechaza la vinculacion
                }
            }
            else {
                callback(false, false) //En caso de que no haya ningun proveedor
            }
        }
    }
</script>
