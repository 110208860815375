<template>
    <div class="animate__animated animate__fadeInUp">
        <div class="row" style="justify-content: center;  margin-bottom: 200px">
            <div style="margin-top: 95px;">
                <img style="width: 200px" src="../../assets/landing/img/waifu1.png">
            </div>
            <div class="title" style="margin-top: 30px;">WAIFU HUMAN 1</div>
            <p style="text-align: center; padding-left: 33%; padding-right: 33%; color: #9D93CC; font-size: 16px;margin-top: 10px;">
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua.”
            </p>
            <div class="row" style="padding-left: 33%; padding-right: 33%;">
                <div class="col-md-3">
                    <div class="box-p">
                        <div>
                            <span class="label">Rarity</span>
                            <span class="label-small">Rarity</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="box-p">
                        <div>
                            <span class="label">Rarity</span>
                            <span class="label-small">Rarity</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="box-p">
                        <div>
                            <span class="label">Rarity</span>
                            <span class="label-small">Rarity</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="box-p">
                        <div>
                            <span class="label">Rarity</span>
                            <span class="label-small">Rarity</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row text-center" style="display: block; margin-top: 20px">
                <button class="btn-box">Preview Mint</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Mint"
    }
</script>

<style scoped>

</style>